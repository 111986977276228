:root {
  --ion-color-primary: #eb182d;
  --ion-color-primary-rgb: 235, 24, 45;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 0, 0, 0;
  --ion-color-primary-shade: #cf1528;
  --ion-color-primary-tint: #ed2f42;
  --ion-color-secondary: #F8F5F1;
  --ion-color-secondary-rgb: 248, 245, 241;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #dad8d4;
  --ion-color-secondary-tint: #f9f6f2;
  --ion-color-tertiary: #607196;
  --ion-color-tertiary-rgb: 96, 113, 150;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #546384;
  --ion-color-tertiary-tint: #707fa1;
  --ion-color-success: #377771;
  --ion-color-success-rgb: 55, 119, 113;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #306963;
  --ion-color-success-tint: #4b857f;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0, 0, 0;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-light: #92949c;
  --ion-color-light-rgb: 146, 148, 156;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #808289;
  --ion-color-light-tint: #9d9fa6;
  --ion-color-medium: #878787;
  --ion-color-medium-rgb: 244, 245, 248;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #949494;
  --ion-color-medium-tint: #f5f6f9;
  --ion-color-dark: #2f2f2f;
  --ion-color-dark-rgb: 47, 47, 47;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #292929;
  --ion-color-dark-tint: #444444;
  --ion-font-family: "Signika Negative", sans-serif;
}