/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

$color-text: #6C727D;
$color-main: #FF3A52;
$color-bg: #F2F4F5;

html {
    caret-color: #1a1a1a;
}

body {
    margin: 0;
    padding: 0;
}

.flip-image {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.alert-wrapper {
    .alert-button {
        color: var(--ion-color-step-550, #737373);
    }
}

.ion-error {
    color: #FF3A52;
    font-size: .8em;
    font-weight: bold;
}

ion-item.item-has-focus > ion-label {
    color: var(--ion-color-primary-contrast) !important;
}

@media only screen and (min-width: 768px) and (min-height: 768px) {
    .fullscreen {
        --height: 98% !important;
    }
}


[hidden] {
    display: none !important;
}

ion-loading.custom-loading {
    --spinner-color: rgb(58, 58, 58);
}

.no-scrollbar::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

@media (min-width: 480px) {
    /* Hide the default scrollbar */
    body {
        scrollbar-width: thin;
        scrollbar-color: #888 transparent; /* Color of the scrollbar thumb */
    }

    /* Customize the scrollbar thumb */
    body::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 5px;
    }

    /* Customize the scrollbar track (optional) */
    body::-webkit-scrollbar-track {
        background-color: transparent;
    }
}

a {
    color: #000;
    text-decoration: none;
}

a:hover {
    box-shadow: 0 2px 0 0 currentColor;
}

.active-link {
    color: var(--ion-color-primary);
}

